import styles from 'styles/Home.module.scss'

const MainLayout = () => {
    return(
        <>
            <div className={styles.mainLayout}>
                <h1>noex</h1>
                <p><b>16</b> years old <b>producer</b> from <b>Hungary</b></p>
            </div>
        </>
    )
}

export default MainLayout
